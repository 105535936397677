import { Component, OnInit } from '@angular/core';
import { MonitorService } from '../monitor.service';
import { Socket } from 'ngx-socket-io';
import { NotasfiscaisService } from '../notasfiscais.service';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';


export class SankhyaProc {
  id: number
  tabela: string
  status: string
  log: string
  data: string
}



@Component({
  selector: 'app-monitoramento2',
  templateUrl: './monitoramento2.component.html',
  styleUrls: ['./monitoramento2.component.css']
})
export class Monitoramento2Component implements OnInit {

  lojas: Array<any> = ['15', '22', '21', '11', '13', '7', '4', '3', '18', '14', '20'];
  status: Array<any> = [];
  statusLoja: Array<any> = [];
  statusClinica: Array<any> = [];
  nfeStatusGeral: {
    otica: number,
    social: number,
  } = { otica: 0, social: 0 };

  sankhya: {
    status: string,
    mensagem: string,
    updatedAt: string,
    qtdTotal: number,
    qtdProcessado: number,
    perc: number,
    wid: string
  }



  sankhyaFilter: {
    dt_i?: string,
    dt_f?: string,
    tabela?: string,
    status?: string,
    filtro?: string,
    autoReload?: boolean
  } = {
      dt_i: new Date().toISOString().split('T')[0],
      dt_f: new Date().toISOString().split('T')[0],
      tabela: '0',
      status: 'PENDENTE',
      filtro: '',
      autoReload: true
    }

  listProc: SankhyaProc[] = [];
  loadingList = false;

  // statusAtu: Array<any> = [];

  constructor(public monitor: MonitorService, private socket: Socket, private nfService: NotasfiscaisService, public http: HttpClient) {
    console.log('monitor', 123)
    try {

      setInterval(() => {
        if (this.sankhyaFilter.autoReload)
          this.buscarProc();
      }, 10000);

      nfService.getNotas().subscribe((data: Array<any>) => {
        this.nfeStatusGeral.otica = data.filter(x =>
          x.sistema == 'OTICA' &&
          x.Log &&
          x.Log.length > 0 &&
          x.Log[x.Log.length - 1].msg
            .toLowerCase()
            .indexOf('erro') >= 0).length;
        this.nfeStatusGeral.social = data.filter(x =>
          x.sistema !== 'OTICA' &&
          x.Log &&
          x.Log.length > 0 &&
          x.Log[x.Log.length - 1].msg
            .toLowerCase()
            .indexOf('erro') >= 0).length;
      });
    } catch (error) {
      console.log('error', error)
    }

    this.status = this.lojas.map((loja) => {
      return { cod_loja: loja }
    })
    console.log('status', this.status)
    // this.statusLoja = this.lojas.map((loja) => {
    //   return { cod_loja: loja }
    // })
    // this.statusClinica = this.lojas.map((loja) => {
    //   return { cod_loja: loja }
    // })
    socket.on('status_geral', data => {
      // console.log('daa', data)
      if (this.status == null) {
        this.status = data.map(it => {
          let pc = (it.qtd * 100 / it.total).toFixed(0);
          return { pc, wid: pc + "%", ...it };
        }).sort((a, b) => { return a.nome > b.nome ? 1 : -1 });
      }
      data.map(it => {
        let pc = (it.qtd * 100 / it.total).toFixed(0);
        let ob = { ...it, pc, wid: pc + "%", ...it };
        let st = this.status.find(x => x.cod_loja == it.cod_loja && x.isLoja == it.isLoja)
        let add = false;
        if (!st) {
          st = {};
          add = true;
        }
        for (let k of Object.keys(ob)) {
          st[k] = ob[k]
        }
        if (add) {
          this.status.push(st);
        }
      });

      this.statusLoja = this.status.filter(x => x.isLoja == true).sort((a, b) => { return a.cod_loja == 15 ? -1 : 1 });
      this.statusClinica = this.status.filter(x => x.isClinica == true).sort((a, b) => { return a.cod_loja == 15 ? -1 : 1 });
      // console.log('status loja', this.statusLoja)
      // console.log('status cli', this.statusClinica)
    })


    setInterval(() => {
      this.getStatusSankhya()
    }, 2000)
  }

  sankhyaFilterT() {
    return JSON.stringify(this.sankhyaFilter);
  }

  ngOnInit() {
  }

  dateFormat(dt){
    return moment(dt).format("DD/MM/YYYY HH:mm:ss");
  }

  async getStatusSankhya() {
    let result = await this.http.get('https://sincronizador.opservidor.com/status')
    result.subscribe((dt) => {
      this.sankhya = dt as any;

      if (this.sankhya && this.sankhya.status == "PROCESSANDO") {
        this.sankhya.perc = (this.sankhya.qtdProcessado * 100 / this.sankhya.qtdTotal);
        this.sankhya.wid = this.sankhya.perc.toFixed(0) + "%";
      }
      console.log("sankhya", this.sankhya)
    });

  }

  async buscarProc() {
    if (this.loadingList)
      return;


    this.loadingList = true;
    if (!this.sankhyaFilter.autoReload) {
      this.listProc = [];
    }

    try {
      let result = await fetch('https://sincronizador.opservidor.com/status/proc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.sankhyaFilter)
      });
      let data = await result.json();
      if(this.sankhyaFilter.autoReload && this.listProc.length > 0){
        let itensToAdd = this.listProc.filter(x=>!data.find(y=>y.id == x.id));
        let itensToRemove = this.listProc.filter(x=>!data.find(y=>y.id == x.id));
        let itensToUpdate = this.listProc.filter(x=>data.find(y=>y.id == x.id));  
        for(let it of itensToAdd){
          this.listProc.push(it);
        }
        for(let it of itensToRemove){
          this.listProc = this.listProc.filter(x=>x.id != it.id);
        }
        for(let it of itensToUpdate){
          let idx = this.listProc.findIndex(x=>x.id == it.id);
          this.listProc[idx] = it;
        }
      }else{
        this.listProc = data as any;
      }
      console.log("sankhya", this.listProc)

    } catch (error) {
      console.log('error', error)
    }

    this.loadingList = false;
  }
}
